<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>分类管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item>
						<el-button type="primary" @click="append({id: 0})">添加一级分类</el-button>
                        <el-button type="primary" @click="setAllExpand(true)">全部展开</el-button>
                        <el-button type="primary" @click="setAllExpand(false)">全部收起</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-tree :props="defaultProps" :load="loadCategory" lazy :default-expand-all="true" ref="tree">
				<div class="custom-tree-node" slot-scope="{ node, data }">
					<el-input v-model="node.label" readonly></el-input>
					<span class="operation">
						<el-button type="primary" @click.stop="() => append(data)"> 增加 </el-button>
						<el-button type="primary" @click.stop="() => edit(node, data)"> 编辑 </el-button>
						<el-button type="danger" @click.stop="() => remove(node, data)"> 删除 </el-button>
					</span>
				</div>
			</el-tree>
		</div>
		<el-dialog title="分类" :visible.sync="dialogVisible" width="30%" custom-class="main-dialog">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="分类名称">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="图标">
					<el-input v-model="form.icon"></el-input>
				</el-form-item>
                <el-form-item label="所含备品">
                    <el-input type="textarea" v-model="form.subNames"></el-input>
                </el-form-item>
				<el-form-item label="排序">
					<el-input v-model="form.sort"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" @click="handleSave">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import http from "../../http.js";

	export default {
		components: {},
		data() {
			return {
				defaultProps: {
					label: 'name',
					children: 'children',
					isLeaf: 'leaf'
				},
				form: {
					id: '',
					parentId: '',
					name: '',
					icon: '',
					sort: '100',
                    subNames: ''
				},
				dialogVisible: false,
				curData: '',
                curNode: '',
				rootNode: ''
			};
		},
		created() {},
		methods: {
            //this.$refs.tree.$children
            setAllExpand(state){
                this.handleSetAllExpand(this.$refs.tree.root, state)
            },
            handleSetAllExpand(node, state){
                node.expanded = state
                var nodes=node.childNodes
                for(var i in nodes){
                    this.handleSetAllExpand(nodes[i], state)
                }
            },
			loadCategory(node, resolve) {
				console.log(node)
				let pid = 0
				if (node.level > 0 && node.data.id) {
					pid = node.data.id
				} else {
					this.rootNode = node
				}
				http.fetchGet("/api/admin/category/list", {
					pid: pid
				}).then(res => {
					resolve(res.data.data);
				});
			},
			append(data) {
				this.form.id = 0
				this.form.parentId = data.id
				this.form.name = ''
				this.form.icon = ''
				this.form.sort = '100'
                this.form.subNames = ''
				this.curData = data
				this.dialogVisible = true
			},
			edit(node, data) {
				this.form = Object.assign({}, data)
                console.log(this.form)
				this.curData = Object.assign({}, data)
                this.curNode = node
				this.dialogVisible = true
			},
			handleSave() {
                this.form.subNames = this.form.subNames.replace(/\s+/g, "|")
				http.fetchPost("/api/admin/category", this.form).then(res => {
					if (res.data.code == 0) {
						this.dialogVisible = false
						let category = res.data.data
						let data = this.curData
						if (this.form.id == 0) {
							if (this.form.parentId > 0) {
								if (!data.children) {
									this.$set(data, 'children', []);
								}
								data.children.push(category)
							} else {
								this.rootNode.loaded = false
								this.rootNode.expand()
							}
						} else {
                            data = category
						}
                        this.curNode.data = data
						this.$message({
							message: '保存成功',
							type: 'success'
						});
					} else {
						this.$message({
							message: res.data.desc,
							type: 'error'
						});
					}
				});
			},
			remove(node, data) {
				this.$confirm("确定要永久删除该分类吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						http.fetchPost("/api/admin/category/del", {
							id: data.id
						}).then((res) => {
							if (res.data.code == 0) {
								console.log(node)
								const parent = node.parent;
								const children = parent.childNodes;
								const index = children.findIndex(d => d.label === node.label);
								children.splice(index, 1);
							}
						});
					})
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
