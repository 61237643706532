<template>
	<div class="account-details">
		<div class="wrapper">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>账户设置</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="account-con">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="main-ruleForm">
					<el-form-item label="账号:" prop="account">
						<el-input v-model="ruleForm.account"></el-input>
					</el-form-item>
                    <el-form-item label="姓名:" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
					<el-form-item label="人员类型:" prop="role">
                        <el-select v-model="ruleForm.role.id" placeholder="请选择人员类型">
                            <el-option
                                v-for="item in roleList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
					</el-form-item>
					<el-form-item label="仓库权限:" prop="warehouse">
						<el-checkbox-group v-model="warehouse" @change="warehouseChange">
							<el-checkbox :label="item" border v-for="(item, idx) in warehouseList" :key="idx">
                            </el-checkbox>
						</el-checkbox-group>
					</el-form-item>
                    <el-form-item label="小组权限:" prop="team">
                        <el-select v-model="ruleForm.verifyTeamId" placeholder="请选择小组">
                            <el-option
                                v-for="item in teamList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
					<el-form-item>
						<el-button class="main-btn" type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components:{
		},
		data() {
			return {
				resetPasswordShow:false,
				roleList:[],
				warehouseList:['BDZB','BFZB','BMZB','BSZB','BTZB','BWZB','BXZB','BYZB','CYZB','BWHDZBK','QKHDZBK'],
				ruleForm: {
                    account: '',
                    name: '',
                    role: {
                        id: ''
                    },
                    verifyTeamId: ''
				},
                warehouse: [],
                rules: {
                    role: [{
                        required: true,
                        message: '请选择用户类型',
                        trigger: 'change'
                    }],
                    account: [{
                        required: true,
                        message: '请输入账号',
                        trigger: 'blur'
                    }],
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }],
				},
                teamList: []
			};
		},
        created() {
            this.getRoleList()
            this.getTeamList()
            if(this.$route.query.id){
                this.ruleForm.id = this.$route.query.id
                this.getData().then(res => {
                    this.ruleForm = res.data.data
                    this.getUserStoreList().then(resStore => {
                        this.warehouse = resStore.data.data.map(e=>e.storeNo)
                    })
                })
            }
        },
        methods: {
            getData(){
                return new Promise(resolve => {
                    this.$http.fetchGet('/api/admin/sysuser', { id: this.ruleForm.id }).then(res => {
                        if(res.data.code == 0){
                            resolve(res)
                        }
                    })
                })
            },
            getUserStoreList(){
                return new Promise(resolve => {
                    this.$http.fetchGet('/api/admin/sysuser/store/list', { userId: this.ruleForm.id }).then(res => {
                        if(res.data.code == 0){
                            resolve(res)
                        }
                    })
                })
            },
            getRoleList(){
                this.$http.fetchGet('/api/admin/role/list', { sort: '+id'}).then(res => {
                    if(res.data.code == 0){
                        this.roleList = res.data.data.list
                    }
                })
            },
            getTeamList(){
                this.$http.fetchGet('/api/admin/team/list').then(res => {
                    if(res.data.code == 0){
                        this.teamList = [{id: 0, name: '请选择小组'}].concat(res.data.data)
                    }
                })
            },
			resetPassword(){
				this.resetPasswordShow=!this.resetPasswordShow
			},
			warehouseChange(e){
				console.log(e)
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
                        this.saveUser().then(res => {
                            this.ruleForm.id = res.data.data.id
                            this.saveStore().then(() => {
                                this.$message.success('保存成功')
                                this.$router.push('/account')
                            }).catch(errStore => {
                                this.$message.error(errStore.data.desc)
                            })
                        }).catch(err => {
                            this.$message.error(err.data.desc)
                        })
                    } else {
						console.log('error submit!!');
						return false;
					}
				});
			},
            saveUser(){
                return new Promise((resolve, reject) => {
                    this.$http.fetchPost('/api/admin/sysuser', this.ruleForm).then(res => {
                        if(res.data.code == 0){
                            resolve(res)
                        }else{
                            reject(res)
                        }
                    })
                })
            },
            saveStore(){
                return new Promise((resolve, reject) => {
                    this.$http.fetchPost('/api/admin/sysuser/store',
                        { userId: this.ruleForm.id, storeList: this.warehouse}).then(res => {
                            if(res.data.code == 0){
                                resolve(res)
                            }else{
                                reject(res)
                            }
                    })
                })
            }
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
